import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <pre><code parentName="pre" {...{}}>{`{% set days = {
  "Mon": "Man",
  "Tue": "Tir",
  "Wed": "Ons",
  "Thu": "Tor",
  "Fri": "Fre",
  "Sat": "Lør",
  "Sun": "Søn",
} %}
{{ days[now().strftime('%a')]}}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      